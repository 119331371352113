<template>
    <div class="mt-auto pb-5 pt-5">
        <ul class="list-unstyled d-flex mb-2 flex-wrap justify-content-center">
            <li>
                <router-link to="/system/terms" class="text-med-gray px-4">
                    Terms and Conditions
                </router-link>
            </li>
            <li>
                <router-link to="/system/privacy" class="text-med-gray px-4">
                    Privacy Policy
                </router-link>
            </li>
            <li>
                <router-link to="/system/support" class="text-med-gray px-4">
                    Support
                </router-link>
            </li>
        </ul>
        <hr />
        <div class="text-med-gray text-center">
            © {{ new Date().getFullYear() }} Bildhive. All rights reserved.
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>
